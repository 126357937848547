.slick-arrow.slick-next, .slick-arrow.slick-prev {
    display: none!important;
}

.slick-dots li button {
    border-radius: 50%;
    background: #0099c26b!important;
}

.slick-dots li button:before {
    color: #0099c2;
}

.slick-dots li.slick-active button:before {
    color: white!important;
}