/* green: #0da03e */
/* blue: #0099c2 */


.sidedrawerWrppaer {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #272727;
    padding-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1000;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
    transform: translateX(-100%);
    transition: all 0.7s ease;
}

.sidedrawerWrppaer.active {
    transform: translateX(0);
}

.topContainer {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #414141be;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}

.xBtnContainer {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #505050;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.8s ease;
}

.xBtn {
    font-size: 0.7rem;
    color: lightgray;
    transition: color 0.8s ease;
}

.slideshowWrapper {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.slideshowContainer {
    width: 100%;
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.xBtnContainer:hover {
    background-color: lightgray;
}

.xBtnContainer:hover .xBtn {
    color: #2c2c2c;
}

.middleContainer {
    width: 100%;
    padding: 0 30px;
    margin: 30px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.slideShow {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.videoContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
}

.headerContainer {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 10%);
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerWhite {
    font-size: 1.5rem;
    font-weight: 600;
    /* color: #0da03e; */
    color: lightgray;
    margin: 10px 0;
}

.link {
    width: 100%;
    height: 40px;
    border: 1px solid #0099c2;
    background-color: #0099c2;
    color: white;
    margin-top: 10px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
}

.socialLinkWrapper {
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.headerWhiteSmall {
    font-size: 1.5rem;
    font-weight: bold;
    color: lightgray;
    margin: 0;
}

.bottomItemContainer {
    width: 100%;
    padding: 0 30px;
    margin: 30px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.textWhite {
    font-size: 0.9rem;
    font-weight: 400;
    margin: 10px 0;
    color:white;
}

.bottomItem {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.item {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
}

.iconContainer {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9e9e9;
    margin-right: 10px;
}

.icon {
    font-size: 20px;
    color: #4c4c4c;
}

.text {
    font-size: 1rem;
    color: white;
}

.socialLinkContainer {
    display: flex;
    margin-top: 20px;
    justify-content: flex-start;
    align-items: center;
}

.socialLink {
    text-decoration: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.socialLink:nth-of-type(1) {
    background-color: #3B5998;
}

.socialLink:nth-of-type(2) {
    background-color: #0077B5;
}

.socialLink:nth-of-type(3) {
    background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7)
}

.socialLink:nth-of-type(4) {
    background-color: #1DA1F2;
}

.socialLinkIcon {
    font-size: 20px;
    color: white;
}

@media (max-width: 767px) {
    .slideshowContainer {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .headerContainer {
        bottom: unset;
        bottom: 5%;
        transform: translate(-50%, 5%);
    }

    .link {
        margin-top: 20px;
    }
}

@media (max-width: 500px) {
    .slideshowWrapper {
        padding: 0 10px;
    }

    .middleContainer {
        padding: 0 10px;
    }

    .headerContainer {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .headerWhite {
        font-size: 1rem;
    }

    .link {
        margin-top: unset;
    }

    .iconContainer, .socialLink {
        height: 30px;
        width: 30px;
    }

    .icon, .socialLinkIcon {
        font-size: 15px;
    }

    .headerWhiteSmall, .headerWhite {
        font-size: 1.2rem;
    }
}