.elementWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerWhite {
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
}

.container {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.itemContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.item {
    width: 250px;
    padding-bottom: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #80808057;
    border-radius: 10px;
    overflow: hidden;
}

.itemImgContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.itemImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.headingContainer {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.headerSmall {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    margin: 10px 0;
}

.text {
    font-size: 13px;
    color: white;
}

.bgContainer {
    width: 300px;
}

.bg {
    width: 100%;
    border-radius: 10px;
}

@media (max-width: 1150px) {
    .elementWrapper {
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .container {
        flex-direction: column;
        overflow: hidden;
    }

    .bgContainer {
        display: none;
    }

    .itemContainer {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .item {
        width: 100%;
        height: 340px;
        justify-content: flex-start;
    }
}

@media (max-width: 767px) {
    .itemContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .container {
        gap: 20px;
    }

    .item {
        padding: 0;
        height: auto;
        align-items: center;
    }

    .headingContainer {
        padding: 20px;
        box-sizing: border-box;
        align-items: center;
    }

    .itemImgContainer {
        width: 100%;
        margin-bottom: 20px;
    }

    .headerSmall {
        text-align: center;
    }

    .text {
        text-align: center;
        margin: 10px auto;
    }
}

@media (max-width: 450px) {
    .itemImgContainer {
        margin-bottom: unset;
    }

    .headingContainer {
        padding: 10px;
    }
}