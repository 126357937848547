.main {
    width: 100%;
    display: block;
    box-sizing: border-box;
}

.titleWrapper {
    width: 300px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title1 {
    display: block;
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(235, 233, 233);
}

.title2 {
    position: relative;
    display: block;
    font-size: 2rem;
    font-weight: 500;
    color: white;
}

.title2::after {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0);
    content: '';
    width: 70%;
    height: 3px;
    border-radius: 50%;
    background-color: white;
}

.testimonial {
    width: 100%;
    margin: 50px auto;
}
