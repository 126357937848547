.logoContainer {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.img {
    width: 100%;
}