.topbarContainer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0.598);
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.elementContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.element1 {
    display: none;
}

@media (max-width: 1024px) {
    .element1 {
        display: flex;
    }

    .element3, .element2 {
        display: none;
    }
}