.ourServicesContainer {
    margin: 50px auto;
    width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.headingWhite {
    font-variant: 1.5rem;
    font-weight: 500;
    color: white;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.itemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.item {
    width: 100%;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.item:nth-of-type(even) {
    flex-direction: row-reverse;
    overflow: hidden;
}

.headerContainer {
    width: 50%;
    padding-right: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.item:nth-of-type(even) .headerContainer {
    padding-left: 40px;
    box-sizing: border-box;
}

.headingGreen {
    position: relative;
    margin: 0;
    margin-bottom: 1rem;
    font-size: 2rem;
    letter-spacing: 1px;
    color: #0da03e;
    font-weight: 400;
}

.headingGreen::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    content: '';
    width: 60%;
    height: 3px;
    background-color: #0da03e;
    border-radius: 10px;
}

.headingBlue {
    position: relative;
    margin: 0;
    margin-bottom: 1rem;
    font-size: 2rem;
    letter-spacing: 1px;
    color: #0099c2;
    font-weight: 400;
}

.headingBlue::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    content: '';
    width: 60%;
    height: 3px;
    background-color: #0099c2;
    border-radius: 10px;
}

.textWhite {
    font-size: 0.9rem;
    color: white;
    line-height: 1.5rem;
    font-weight: 400;
    text-align: left;
}

.linkGreen {
    position: relative;
    width: 200px;
    height: 40px;
    border: 1px solid #0da03e;
    background-color: transparent;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.linkGreenText {
    color: #0da03e;
    font-size: 0.9rem;
    z-index: 1;
    transition: color 1s ease;
}

.linkGreenSlider {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #0da03e;
    transition: width 1s ease;
    z-index: 0;
}

.linkGreen:hover .linkGreenText {
    color: white;
}

.linkGreen:hover .linkGreenSlider {
    width: 100%;
}

.linkBlue {
    position: relative;
    width: 200px;
    height: 40px;
    border: 1px solid #0099c2;
    background-color: transparent;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.linkBlueText {
    color: #0099c2;
    font-size: 0.9rem;
    z-index: 1;
    transition: color 1s ease;
}

.linkBlueSlider {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #0099c2;
    transition: width 1s ease;
    z-index: 0;
}

.linkBlue:hover .linkBlueText {
    color: white;
}

.linkBlue:hover .linkBlueSlider {
    width: 100%;
}

.videoContainer {
    width: 50%;
    height: 350px;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1024px) {
    .ourServicesContainer {
        width: calc(100% - 150px);
    }
    
    .item, .item:nth-of-type(even) {
        flex-direction: column-reverse;
    }

    .videoContainer {
        width: 100%;
        height: auto;
        margin-bottom: 25px;
    }

    .headerContainer {
        width: 100%;
        margin-top: 25px;
        align-items: center;
        padding-right: unset;
    }

    .item:nth-of-type(even) .headerContainer {
        padding-left: unset;
    }

    .headingWhite {
        margin: auto;
    }

    .headingGreen {
        text-align: center;
    }

    .headingGreen::after {
        left: 50%;
        transform: translate(-50%, 0);
    }

    .headingBlue {
        text-align: center;
    }

    .headingBlue::after {
        left: 50%;
        transform: translate(-50%, 0);
    }

    /* .textWhite {
        text-align: center;
    } */
}

@media (max-width: 767px) {
    .ourServicesContainer {
        width: calc(100% - 75px);
        margin: 20px auto;
    }

    .headerContainer {
        margin-top: 20px;
    }

    .item {
        margin: 20px auto;
    }

    .headingBlue, .headingGreen {
        font-size: 1.5rem;
    }

    .linkGreen {
        width: calc(100% - 5px);
        background-color: #0da03e;
    }
    
    .linkGreenText {
        color: white;
        font-size: 0.8rem;
    }

    .linkBlue {
        width: calc(100% - 5px);
        background-color: #0099c2;
    }

    .linkBlueText {
        color: white;
        font-size: 0.8rem;
    }
}