.spinner {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #272727;
}