.homepageContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bannerContainer {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    height: 100vh;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
}

.headerContainer {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headingWhite {
    display: block;
    width: 100%;
    overflow: hidden;
    font-size: 2rem;
    max-height: 0;
    color: lightgray;
    letter-spacing: 1px;
    margin: 5px 0;
    font-weight: 500;
    text-align: center;
    transition: all 3.5s linear;
}

.headingWhite.animate {
    max-height: 500px;
}

.textWhite {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin: 5px 0;
}

.link {
    position: relative;
    width: 200px;
    height: 40px;
    margin-top: 40px;
    border: 1px solid #0da03e;
    background-color: #0da03e;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
}

.linkText {
    z-index: 1;
}

.linkSlider {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #0099c2;
    z-index: 0;
    transition: all 0.7s ease;
}

.link:hover {
    border: 1px solid #0099c2;
}

.link:hover .linkSlider {
    width: 100%;
}

.introContainer {
    width: calc(100% - 300px);
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.introHeadingContainer {
    width: 50%;
    padding-right: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.introBgContainer {
    width: 50%;
    height: 375px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.introBg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.headingBlue {
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 2.5rem;
    margin: 0;
    margin-bottom: 1rem;
    color: #0099c2;
}

.introTextWhite {
    font-size: 0.9rem;
    line-height: 1.5rem;
    color: white;
}

.introLink {
    position: relative;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    border: 1px solid #0099c2;
    background-color: transparent;
    margin-top: 40px;
    font-size: 0.9rem;
}

.introLinkText {
    z-index: 1;
    color: #0099c2;
    transition: color 1s ease;
}

.introLinkSlider {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #0099c2;
    z-index: 0;
    transition: all 1s ease;
}


.introLink:hover .introLinkSlider {
    width: 100%;
}

.introLink:hover .introLinkText {
    color: white;
}

.outroContainer {
    width: calc(100% - 300px);
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.outroHeadingContainer {
    width: 50%;
    padding-right: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.headingGreen {
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 2.5rem;
    margin: 0;
    margin-bottom: 1rem;
    color: #0da03e;
}

.outroBg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(0.7);
}

.outroLink {
    position: relative;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    border: 1px solid #0da03e;
    background-color: transparent;
    margin-top: 40px;
    font-size: 0.9rem;
}

.outroLinkText {
    z-index: 1;
    color: #0da03e;
    transition: color 1s ease;
}

.outroLinkSlider {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #0da03e;
    z-index: 0;
    transition: all 1s ease;
}


.outroLink:hover .outroLinkSlider {
    width: 100%;
}

.outroLink:hover .outroLinkText {
    color: white;
}

.wrapper {
    width: calc(100% - 300px);
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonialWrapper {
    width: calc(100% - 300px);
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1024px) {
    .headerContainer {
        width: 95%;
        bottom: unset;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .introContainer, .outroContainer {
        flex-direction: column-reverse;
        width: calc(100% - 150px);
    }

    .introBgContainer {
        height: auto;
        width: 100%;
        margin-bottom: 25px;
    }

    .introHeadingContainer, .outroHeadingContainer {
        width: 100%;
        box-sizing: border-box;
        margin: 25px auto;
        align-items: center;
        padding-right: unset;
    }

    .headingBlue, .headingGreen {
        text-align: center;
        font-size: 1.5rem;
    }

    .textWhite {
        text-align: center;
    }

    .introTextWhite {
        text-align: center;
    }

    .wrapper {
        width: 100%;
    }

    .testimonialWrapper {
        width: 100%;
    }

    .link {
        width: 100%;
    }
}

@media (max-width: 767px) {

    .introContainer, .outroContainer {
        width: calc(100% - 75px);
    }

    .introLink {
        width: 100%;
        background-color: #0099c2;
    }

    .introLinkText {
        color: white;
    }

    .outroLink {
        width: 100%;
        background-color: #0da03e;
    }

    .outroLinkText {
        color: white;
    }
}
