.navbarContainer {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navLink {
    font-size: 0.9rem;
    margin: 0 5px;
    color: lightgray;
    text-decoration: none;
    transition: color 0.5s linear;
}

.navLink:hover {
    color: white;
}

@media (max-width: 1024px) {
    .navbarContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .navLink {
        font-size: 1.2rem;
        margin: 5px 0;
    }
}