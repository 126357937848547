.templateWrapper {
    position: relative;
    width: 90%;
    height: 100%;
    display: block;
    margin: 50px auto;
}

.outerBox {
    position: absolute;
    top: -15px;
    left: -15px;
    width: 60%;
    height: calc(100% + 30px);
    background-color: grey;
    z-index: -1;
    border-radius: 10px 0 0 10px;
}

.innerBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px;
    box-sizing: border-box;
    z-index: 1;
    background-color: #606060;
    border-radius: 10px;
}

.textContainer {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.comment {
    display: block;
    margin: 10px 0;
    font-size: 0.9rem;
    font-weight: 400;
    color: white;
}

.ratings {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.title {
    display: block;
    margin: 10px 0;
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
}

.avatarContainer {
    position: relative;
    width: 45%;
    height: 400px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatarOuter {
    position: absolute;
    top: 0;
    right: 0;
    background-color: grey;
    width: 80%;
    height: 80%;
    z-index: 0;
    border-radius: 0 10px 0 0;
}

.avatarMiddle {
    width: 100%;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #606060;
    z-index: 1;
    border-radius: 10px;
}

.avatarInner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1023px) {
    .innerBox {
        flex-direction: column-reverse;
    }

    .avatarContainer {
        width: 100%;
    }

    .textContainer {
        width: 90%;
    }
}